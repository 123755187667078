<template>
  <el-card class="sett_list">
    <el-row>
      <el-col>
        <el-button type="primary" size="small" @click="clickAdd">新增</el-button>
        <el-button type="danger" size="small" @click="clickDel" :disabled="btn.delete">删除</el-button>
      </el-col>
    </el-row>
    <el-table class="vg_mt_16" :data="settList" border @selection-change="handleSelectionChange" @select="selectRows" @select-all="selectRows">
      <el-table-column type="selection"></el-table-column>
      <el-table-column type="index" label="序号" align="center" min-width="15px"></el-table-column>
      <el-table-column label="参数名" prop="sett_key" align="center"></el-table-column>
      <el-table-column label="系数值" prop="sett_value" align="right"></el-table-column>
      <el-table-column label="参数类型" prop="sett_type" :formatter="typeFormatter" align="center" />
      <el-table-column label="参数注释" prop="sett_remark" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="editRow(scope.row)" :disabled="scope.row.sett_type === '0' || btn.edit" size="small" plain type="primary">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--    <el-row>-->
    <!--      <el-col :md="24">-->
    <!--        <pubPagination :totalPage="total" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>-->
    <!--      </el-col>-->
    <!--    </el-row>-->

    <el-dialog :title="getTitle()" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <el-form ref="addSettRef" :model="addSettForm" :inline="true" :rules="settFormRule" label-width="120px">
        <el-row>
          <el-col :md="14" :offset="5">
            <el-form-item label="参数名:" prop="sett_key" class="w1">
              <el-input
                v-model.trim="addSettForm.sett_key"
                size="small"
                placeholder="请输入参数名"
                clearable
                :disabled="addSettForm.type === 1"
                maxlength="50"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="14" :offset="5">
            <!-- @change="addSettForm.sett_value = helper.calcPrice(addSettForm.sett_value, 4, 100000000000000000000000000000)" -->
            <!-- @input="addSettForm.sett_value = helper.keepTNum1(addSettForm.sett_value)" -->
            <el-form-item label="参数值:" prop="sett_value">
              <el-input
                v-model.trim="addSettForm.sett_value"
                size="small"
                placeholder="请输入参数值"
                clearable
                @input="
                  addSettForm.sett_value =
                    addSettForm.sett_value
                      .replace(/^0+(\d)/, '$1')
                      .replace(/^\./, '0.')
                      .match(/^\d*(\.?\d{0,4})/g)[0] || ''
                "
                @change="addSettForm.sett_value = helper.calcPrice(addSettForm.sett_value, 4)"
                maxlength="255"
                show-word-limit
              ></el-input>
            </el-form-item>
            <!--    @input="addSettForm.sett_value = addSettForm.sett_value.toString().match(/^\d+(?:\.\d{0,4})?/)[0] || ''"-->
            <!-- oninput="value=value.toString().match(/^\d+(?:\.\d{0,2})?/)"> -->
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="14" :offset="5">
            <el-form-item label="参数类型:" prop="sett_type" placeholder="请选择参数类型">
              <el-select v-model="addSettForm.sett_type" size="small" clearable :disabled="addSettForm.type === 1">
                <el-option v-for="item in settType" :key="item.value" :value="item.value" :label="item.label"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="14" :offset="5">
            <el-form-item label="参数备注:" prop="sett_remark">
              <el-input
                v-model="addSettForm.sett_remark"
                size="small"
                type="textarea"
                clearable
                :autosize="{ minRows: 4, maxRows: 5 }"
                maxlength="255"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clickCancel" size="small">取 消</el-button>
        <el-button type="primary" @click="clickConfirm" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import pubPagination from '@/components/common/pubPagination';
import { get, post } from '@/api/request';
import { settAPI } from '@/api/modules/sett';
export default {
  name: 'settList',
  components: {
    pubPagination
  },
  data() {
    return {
      searchForm: {
        page_no: 0
      },
      settList: [],
      multiSelection: [],
      total: 0,
      dialogVisible: false,
      btn: {
        delete: false
      },
      addSettForm: {
        type: null,
        sett_key: null,
        sett_value: '',
        sett_type: null,
        sett_remark: null,
        sett_id: null
      },
      settType: [
        { value: 0, label: '系统参数' },
        { value: 1, label: '业务参数' }
      ],
      settFormRule: {
        sett_key: [{ required: true, message: ' ', trigger: 'blur' }],
        sett_value: [{ required: true, message: ' ', trigger: 'blur' }],
        sett_type: [{ required: true, message: ' ', trigger: 'blur' }],
        sett_remark: [{ required: true, message: ' ', trigger: 'blur' }]
      }
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getSettList();
    },
    getSettList() {
      get(settAPI.getSetts)
        .then(res => {
          if (res.data.code === 0) {
            this.settList = res.data.data.list;
            this.total = res.data.data.total;
            this.btn = res.data.data.btn;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    clickAdd() {
      this.dialogVisible = true;
      this.addSettForm.type = 0;
    },
    clickDel() {
      if (this.multiSelection.length === 0) {
        return this.$message.warning('至少选择一条信息');
      }
      this.$confirm('确定删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const arr = [];
          this.multiSelection.forEach(item => {
            arr.push(item.sett_id);
          });
          post(settAPI.destroySettByIds, { sett_id_list: arr })
            .then(res => {
              if (res.data.code === 0) {
                this.$message.success(res.data.msg);
                this.getSettList();
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(res => {});
    },
    handleClose() {
      this.$refs.addSettRef.resetFields();
      this.dialogVisible = false;
    },
    typeFormatter(row) {
      let str = '';
      this.settType.forEach(item => {
        if (item.value === row.sett_type) {
          str = item.label;
        }
      });
      return str;
    },
    editRow(row) {
      this.addSettForm.type = 1;
      this.addSettForm.sett_key = row.sett_key;
      this.addSettForm.sett_value = row.sett_value;
      this.addSettForm.sett_type = row.sett_type;
      this.addSettForm.sett_remark = row.sett_remark;
      this.addSettForm.sett_id = row.sett_id;
      this.dialogVisible = true;
    },
    // 多选获取勾选内容
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = selection.some(item => item.sett_type === '0');
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getRequs();
    },
    getTitle() {
      if (this.addSettForm.type === 0) {
        return '新增';
      } else {
        return '修改';
      }
    },
    clickCancel() {
      this.$refs.addSettRef.resetFields();
      this.dialogVisible = false;
    },
    //确定按钮
    clickConfirm() {
      this.$refs.addSettRef.validate(valid => {
        if (valid) {
          let title = '';
          if (this.addSettForm.type === 0) {
            title = '确定添加';
          } else {
            title = '确定修改';
          }
          this.$confirm(title + '?', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              if (this.addSettForm.type === 0) {
                this.saveFun1();
              } else {
                this.saveFun2();
              }
            })
            .catch(() => {});
        }
      });
    },
    //新增
    saveFun1() {
      const form = {
        sett_key: this.addSettForm.sett_key,
        sett_value: this.addSettForm.sett_value,
        sett_remark: this.addSettForm.sett_remark,
        sett_type: this.addSettForm.sett_type
      };
      post(settAPI.addSett, form)
        .then(res => {
          console.log(res);
          if (res.data.code === 0) {
            this.$refs.addSettRef.resetFields();
            this.dialogVisible = false;
            this.$message.success(res.data.msg);
            this.getSettList();
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //修改
    saveFun2() {
      const form = {
        sett_key: this.addSettForm.sett_key,
        sett_value: this.addSettForm.sett_value,
        sett_remark: this.addSettForm.sett_remark,
        sett_type: this.addSettForm.sett_type,
        sett_id: this.addSettForm.sett_id
      };
      post(settAPI.editSett, form)
        .then(res => {
          console.log(res);
          if (res.data.code === 0) {
            this.$refs.addSettRef.resetFields();
            this.dialogVisible = false;
            this.$message.success(res.data.msg);
            this.getSettList();
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  width: 100%;
}
::v-deep .el-form-item__content {
  width: 70%;
}
</style>
